import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App/App';
import 'bootstrap/scss/bootstrap.scss';
import userStore from './Stores/userStore';
import keycloak from './Services/Keycloak/KCInstance';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

keycloak
  .init({
    onLoad: 'check-sso'
  })
  .then((authenticated) => {
    userStore.setAuth(authenticated);
    root.render(
      <React.StrictMode>
        <App auth={userStore.auth} />
      </React.StrictMode>
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
