import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import Login from '../Pages/Unauthorized/Login/Login';

export function PublicRoutes() {
  return (
    <Routes>
      <Route index element={<Login />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="*" element={<div>not found</div>}></Route>
    </Routes>
  );
}
