import { makeAutoObservable } from 'mobx';
import userService from '../Services/API/userService';

class UserStore {
  auth: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(auth: boolean) {
    this.auth = auth;
  }
}

const userStore = new UserStore();
export default userStore;
