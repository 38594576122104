import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import Button, { IButtonType } from '../../Components/Common/Button/button';
import React from 'react';
import './index.scss';

export interface IConfirmationModalProps {
  title: string;
  text: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  open: boolean;
  onClose: (value: boolean) => void;
}

export default function ConfirmationModal({
  title,
  text,
  confirmBtnText,
  cancelBtnText,
  open,
  onClose
}: IConfirmationModalProps) {
  const handleClose = (value: boolean) => {
    onClose(value);
  };

  return (
    <Dialog style={{ zIndex: '999999' }} open={open} id="dialog-01">
      <DialogTitle className="p-4 pb-3 confirm-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className="dialog-body-style">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="d-flex flex-wrap pb-4 p-4 pt-0">
        <Button
          style={{
            width: 'auto',
            height: '42px',
            margin: 0,
            marginLeft: '16px'
          }}
          buttonType={IButtonType.cancel_button}
          buttonText={cancelBtnText || 'Cancelar'}
          onClick={(_: any) => handleClose(false)}
        />
        <Button
          style={{ width: 'auto', height: '42px' }}
          onClick={(_: any) => handleClose(true)}
          buttonText={confirmBtnText || 'Confirmar'}
          buttonType={IButtonType.purple_button}
        />
      </DialogActions>
    </Dialog>
  );
}
