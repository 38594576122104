import React, { useEffect, useMemo, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import './graphics.scss';
import EmbedGraphic from '../EmbedGraphic';
import Button, { IButtonType } from '../../Common/Button/button';
import { TypeComponent } from '../../../Models/IChatMessage';
import openaiService from '../../../Services/API/openaiService';
import chatStore from '../../../Stores/chatStore';

export interface IGraphicsProps {
  zipContent: { fileName: string; content: any }[];
}

export default function Graphics({ zipContent }: IGraphicsProps) {
  const htmlFiles = zipContent.filter((f) => f.fileName.includes('.html'));
  const jsonFile = zipContent.find((x) => x.fileName.includes('.json'));

  useEffect(() => {
    const recommendations = JSON.parse(jsonFile?.content);
    // eslint-disable-next-line
  }, []);
  function getGraphName(fileName: string) {
    const fileNumber = fileName.split('.')[0];
    const recommendations = JSON.parse(jsonFile?.content);
    const chart = recommendations.find(
      (x: any) => Number(x.id) === Number(fileNumber)
    );
    return chart.type;
  }

  function getGraphCode(fileName: string) {
    const fileNumber = fileName.split('.')[0];
    const recommendations = JSON.parse(jsonFile?.content);
    const chart = recommendations.find(
      (x: any) => Number(x.id) === Number(fileNumber)
    );
    return chart.code;
  }

  return (
    <Tabs style={{ marginTop: '27px', marginBottom: '30px' }}>
      <TabList className="tabList d-flex gap-3">
        {htmlFiles.map((file, index) => {
          return <Tab key={index}>{getGraphName(file.fileName)}</Tab>;
        })}
      </TabList>

      {htmlFiles.map((file, index) => {
        return (
          <TabPanel key={index}>
            <EmbedGraphic content={file.content} />
            <Button
              onClick={(_: any) => {
                chatStore.reprompt = true;
                chatStore.addCode(getGraphCode(file.fileName));
                chatStore.addChatMessage(TypeComponent.ChatMessage, {
                  chatMessage:
                    'Você solicitou editar o gráfico em barras, o que você gostaria de mudar?',
                  promptFlagSide: true
                });
                document.getElementById('chat-input')?.focus();
              }}
              buttonText="Editar gráfico"
              buttonType={IButtonType.simple_button_with_class}
              classes="roxo-chat"
            ></Button>
          </TabPanel>
        );
      })}
    </Tabs>
  );
}
