import { action, autorun, makeAutoObservable, runInAction, toJS } from 'mobx';
import React from 'react';
import ChatMessage, {
  IChatMessageProps
} from '../Components/Common/ChatMessage';
import IChatProps, { TypeComponent } from '../Models/IChatMessage';
import Graphics, {
  IGraphicsProps
} from '../Components/Specific/Graphics/graphics';
import { extractFileFromZip } from '../Utils/zip';
import { inputErrorMessages } from '../Utils/constants';

// Responsável por armazenas globalmente coisas relacionadas ao chat
class ChatStore {
  chatMessages: Array<IChatProps> = [
    {
      componentType: TypeComponent.ChatMessage,
      component: ChatMessage,
      props: {
        chatMessage:
          'Muito bem, agora que seus dados foram processados, o que você gostaria de fazer?',
        promptFlagSide: true,
        typing: true
      }
    }
  ];

  chatLoading: boolean = false;

  // Inicio variavéis reprompt
  reprompt: boolean = false;
  code: string = '';
  //  Fim variavéis reprompt

  constructor() {
    makeAutoObservable(this);
  }

  // Realiza Limpeza do Chat.
  cleanChat() {
    this.chatMessages = [];
    this.chatMessages.push({
      componentType: TypeComponent.ChatMessage,
      component: ChatMessage,
      props: {
        chatMessage:
          'Muito bem, agora que seus dados foram processados, o que você gostaria de fazer?',
        promptFlagSide: true,
        typing: true
      }
    });
  }

  updateFirstMessage(message: string) {
    if (message !== this.chatMessages[0].props.chatMessage) {
      this.chatMessages[0] = {
        componentType: TypeComponent.ChatMessage,
        component: ChatMessage,
        props: {
          chatMessage: message,
          promptFlagSide: true,
          typing: true
        }
      };
    }
  }

  //   Adiciona um novo componente de chat.
  addChatMessage(componentType: TypeComponent, props: IChatMessageProps) {
    const component = ChatMessage;

    const newChatMessage: IChatProps = {
      componentType,
      component,
      props
    };
    this.chatMessages.push(newChatMessage);
  }

  // Adiciona um novo componente de gráfico no chat.
  addChatGraphic(componentType: TypeComponent, props: IGraphicsProps) {
    const component = Graphics;

    const newGraphic: IChatProps = {
      componentType,
      component,
      props
    };
    this.chatMessages.push(newGraphic);
  }

  addChatGraphicFromZip(zipBuffer: ArrayBuffer) {
    this.addChatLoading();

    extractFileFromZip(zipBuffer)
      .then((zipFiles) => {
        this.removeChatLoading();
        if (zipFiles) {
          chatStore.addChatGraphic(TypeComponent.Grafico, {
            zipContent: zipFiles
          });
          chatStore.addChatMessage(TypeComponent.ChatMessage, {
            chatMessage: `Tudo certo com o que você pediu? Veja mais opções de visualização personalizadas que criamos para você!`,
            promptFlagSide: true
          });
        } else {
          chatStore.addChatMessage(TypeComponent.ChatMessage, {
            chatMessage: `Erro ao carregar a sua solicitação!`,
            promptFlagSide: true
          });
        }
        this.changeRepromptStatus(false);
      })
      .catch((_) => {
        this.removeChatLoading();
        this.addChatInputError();
        this.changeRepromptStatus(false);
      });
  }

  updateChatMessageTyping() {
    if (this.chatMessages.length > 0) {
      this.chatMessages[this.chatMessages.length - 1].props.typing = false;
    }
  }

  // Adiciona um loading component de chat.
  addChatLoading() {
    this.chatLoading = true;
    const component = ChatMessage;

    const newChatMessage: IChatProps = {
      componentType: TypeComponent.ChatMessage,
      component,
      props: {
        chatMessage: '',
        promptFlagSide: true,
        loading: true
      } as IChatMessageProps
    };
    this.chatMessages.push(newChatMessage);
  }

  //   Adiciona um novo componente de chat.
  removeChatLoading() {
    this.chatLoading = false;
    this.chatMessages.pop();
  }

  changeRepromptStatus(bool: boolean) {
    this.reprompt = bool;
  }

  addChatInputError() {
    const random = Math.floor(Math.random() * inputErrorMessages.length);
    this.addChatMessage(TypeComponent.ChatMessage, {
      chatMessage: inputErrorMessages[random],
      promptFlagSide: true
    });
  }

  addCode(code: string) {
    this.code = code;
  }

  getChatMessages() {
    return this.chatMessages;
  }
}

const chatStore = new ChatStore();
export default chatStore;
