import React from 'react';
import './Loader.scss';

export default function Loader() {
  return (
    <div className="loading">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
