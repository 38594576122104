import React, { forwardRef } from 'react';
import copySvg from '../../../Assets/copy.svg';
import { Toaster } from 'react-hot-toast';
import { copyTextToClipboard } from '../../../Utils/globalFunctions';
import './input.scss';

export enum IInputType {
  default,
  input_with_button
}

interface IInputProps {
  inputType: IInputType;
  [key: string]: any;
}

function Input({ inputType, ...rest }: IInputProps, ref: any) {
  switch (inputType) {
    case IInputType.input_with_button:
      // @ Obs: Quando utilizar esse tipo de input, passar o textlabel como parâmetro.
      return <input {...rest} ref={ref} className="inputWithButton" />;
    default:
      return <input {...rest} ref={ref} />;
  }
}

export default forwardRef(Input);
