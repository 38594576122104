import toast from 'react-hot-toast';

export const copyTextToClipboard = (textToCopy: string) => {
  navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      toast.success('Text copied to clipboard!');
    })
    .catch(() => {
      toast.success('Error copying text to clipboard!');
    });
};
