import React from 'react';
import Input, { IInputType } from '../Input/input';
import Button, { IButtonType } from '../Button/button';

interface IInputWithButton {
  inputProps: any;
  buttonProps: any;
}

const InputWithButton: React.FC<IInputWithButton> = ({
  inputProps,
  buttonProps
}) => {
  return (
    <div
      className="d-flex position-relative align-items-center gap-3"
      style={{
        height: '48px',
        borderRadius: '8px',
        backgroundColor: inputProps?.disabled ? '#E9E7EE' : 'inherit',
        opacity: inputProps?.disabled ? '0.5' : '1',
        pointerEvents: inputProps?.disabled ? 'none' : 'auto'
      }}
    >
      <Input inputType={IInputType.input_with_button} {...inputProps} />
      <Button
        style={{ position: 'absolute', right: '4px', height: '40px' }}
        buttonType={IButtonType.purple_button}
        buttonText="Enviar"
        {...buttonProps}
      />
    </div>
  );
};

export default InputWithButton;
