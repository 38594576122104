export const inputErrorMessages = [
  'Desculpe, não consegui entender o que você quis dizer, você poderia me explicar novamente, por favor?',
  'Não tenho certeza se entendi corretamente, poderia me explicar com mais detalhes, por favor?',
  'Me desculpe, mas estou com dificuldade para compreender sua mensagem. Seria possível reformulá-la?',
  'Desculpe-me, não consegui captar o significado de sua frase. Poderia elaborar um pouco mais?',
  'Peço desculpas, mas estou tendo problemas para interpretar sua mensagem. Seria possível fornecer mais detalhes?',
  'Desculpe-me pela minha confusão, mas não estou conseguindo compreender o que você está tentando transmitir. Poderia ser mais claro?',
  'Me desculpe se estou confuso, mas não estou conseguindo compreender sua intenção. Poderia me dar mais informações?',
  'Desculpe-me, mas estou encontrando dificuldades em decifrar sua mensagem. Será que poderia explicar de outra forma?',
  'Sinto muito, mas estou tendo problemas para entender exatamente o que você está dizendo. Poderia simplificar sua mensagem?',
  'Peço desculpas, mas estou tendo dificuldades em compreender seu pedido. Seria possível detalhá-lo melhor?'
];
