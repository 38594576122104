import React, { useEffect, useRef, useState } from 'react';
import './GerarGraficos.scss';
import InputWithButton from '../../../Components/Common/InputWithButton';
import HomePage from '../../../Components/Layout/HomePage/HomePage';
import openaiService from '../../../Services/API/openaiService';
import { extractFileFromZip } from '../../../Utils/zip';
import ViewFiles from '../../../Components/Specific/ViewFiles/ViewFiles';
import { observer } from 'mobx-react';
import { TypeComponent } from '../../../Models/IChatMessage';
import chatStore from '../../../Stores/chatStore';
import globalStore from '../../../Stores/globalStore';

function GerarGraficos(this: any) {
  const chatMessages = chatStore.getChatMessages();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (globalStore.getDatasets().length === 0) {
      chatStore.updateFirstMessage(
        'Oops! Parece que você ainda não selecionou nenhum dado. Vá para a página "Preparar Dados" e realize essa etapa. Assim, poderei gerar os gráficos que você deseja com facilidade.'
      );
    } else {
      chatStore.updateFirstMessage(
        'Muito bem, agora que seus dados foram processados, o que você gostaria de fazer?'
      );
    }
  }, []);
  function repromptGraph(objetivo: string) {
    chatStore.changeRepromptStatus(true);
    chatStore.addChatLoading();
    openaiService.sdp_reprompt(objetivo, chatStore.code).then((zipBuffer) => {
      chatStore.removeChatLoading();
      if (zipBuffer) {
        chatStore.addChatGraphicFromZip(zipBuffer);
      }
    });
  }

  function generateGraphs(objetivo: string) {
    chatStore.addChatLoading();
    openaiService.generateGraphs(objetivo).then((zipBuffer) => {
      if (zipBuffer) {
        extractFileFromZip(zipBuffer).then((zipFiles) => {
          if (zipFiles) {
            chatStore.removeChatLoading();
            chatStore.addChatGraphic(TypeComponent.Grafico, {
              zipContent: zipFiles
            });
            chatStore.addChatMessage(TypeComponent.ChatMessage, {
              chatMessage: `Tudo certo com o que você pediu? Veja mais opções de visualização personalizadas que criamos para você!`,
              promptFlagSide: true
            });
          }
        });
      } else {
        chatStore.removeChatLoading();
        chatStore.addChatInputError();
      }
    });
  }

  function onClickButton() {
    if (inputRef?.current && inputRef.current.value.length > 0) {
      chatStore.addChatMessage(TypeComponent.ChatMessage, {
        promptFlagSide: false,
        chatMessage: inputRef.current.value
      });

      if (chatStore.reprompt) {
        repromptGraph(inputRef.current.value);
      } else {
        generateGraphs(inputRef.current.value);
      }
      inputRef.current.value = '';
    }
  }

  return (
    <>
      <div className="d-flex flex-column flex-grow-1 page-content" id="chat-01">
        <div>
          <HomePage
            text="Aqui as conversas se transformam em ideias inovadoras. 
        Descubra como nosso chat pode ajudá-lo a obter informações valiosas 
        e tomar decisões mais assertivas."
          />
        </div>
        <div className="view-fixed">
          <ViewFiles />
        </div>

        <div className="page-container flex-grow-1">
          <div className="chat-container">
            {chatMessages.map((chat, index) => {
              const ChatComponent = chat.component;
              if (ChatComponent)
                return <ChatComponent key={index} {...chat.props} />;
              else return <div>Não foi possível carregar o componente</div>;
            })}
          </div>
        </div>
        <div
          className="input-fixed"
          onKeyDown={(e) => {
            if (e.key === 'Enter') onClickButton();
          }}
        >
          <InputWithButton
            inputProps={{
              id: 'chat-input',
              placeholder: 'Em que posso te ajudar hoje?',
              ref: inputRef,
              maxLength: 1000,
              autoComplete: 'off',
              disabled:
                chatStore.chatLoading || globalStore.datasets.length <= 0
            }}
            buttonProps={{ onClick: onClickButton.bind(this) }}
          />
        </div>
      </div>
    </>
  );
}

export default observer(GerarGraficos);
