import React from 'react';

export enum TypeComponent {
  ChatMessage = 0,
  Grafico = 1
}
export default interface IChatProps {
  componentType: TypeComponent;
  component?: React.ComponentType<any>;
  props: any;
}
