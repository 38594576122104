import React from 'react';
import './Navbar.scss';
import { NavLink } from 'react-router-dom';

export default function Navbar() {
  const navPages = [
    { pageName: 'Preparar Dados', pageUrl: '/preparardados', active: true },
    { pageName: 'Gerar Gráficos', pageUrl: '/gerargraficos', active: false }
  ];

  return (
    <nav className="c2d_navbar">
      {navPages.map((i, index) => {
        return (
          <NavLink key={index} to={i.pageUrl} replace={true}>
            {i.pageName}
          </NavLink>
        );
      })}
    </nav>
  );
}
