import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import GerarGraficos from '../Pages/Authorized/GerarGraficos/GerarGraficos';
import PrepararDados from '../Pages/Authorized/PrepararDados/PrepararDados';
export function PrivateRoutes() {
  return (
    <Routes>
      <Route index path="*" element={<div>No Match</div>}></Route>
      <Route path="/preparardados" element={<PrepararDados />}></Route>
      <Route path="/gerargraficos" element={<GerarGraficos />}></Route>
      <Route path="/" element={<Navigate to={'/preparardados'} />}></Route>
    </Routes>
  );
}
