import React, { useContext, useEffect, useState } from 'react';
import Table from '../../../UI/Table';
import PreviewEyeOpen from '../../../../Assets/preview_eye_open.svg';
import PreviewEyeClosed from '../../../../Assets/preview_eye_closed.svg';
import EditIcon from '../../../Assets/edit.svg';
import style from './DatasetItem.module.scss';
import toast, { Toaster } from 'react-hot-toast';
import globalStore from '../../../../Stores/globalStore';
import { observer } from 'mobx-react';
import openaiService from '../../../../Services/API/openaiService';

interface DatasetItemProps {
  label: string;
  radioDisabled?: boolean;
  clickEvent?: any;
  showData?: boolean;
}

function DatasetItem({
  label,
  radioDisabled,
  clickEvent,
  showData = false
}: DatasetItemProps) {
  const DBSCHEMA = 'service';
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [datasetMetadata, setDatasetMetadata] = useState<any>();
  const [datasetPreview, setDatasetPreview] = useState<any[]>([]);
  const [datasetColumns, setDatasetColumns] = useState<any[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    if (showData) {
      callDatasetPreview();
    }
    // eslint-disable-next-line
  }, [showData]);
  const loadData = () => {
    callDatasetPreview();
  };

  const callDatasetPreview = () => {
    setShowLoading(true);
    openaiService
      .callApiConnectorMetadataPreview(DBSCHEMA, label)
      .then((res) => {
        if (res) {
          const data = res;
          setDatasetMetadata({
            row: data.row_count,
            column: data.column_count
          });
          const rowsData: [] = JSON.parse(data.data);
          setDatasetPreview(rowsData);
          const columns = rowsData.map((obj) => Object.keys(obj));
          setDatasetColumns(columns.length === 0 ? [] : columns[0]);
          setShowLoading(false);
          setShowPreview(true);
        } else {
          setShowPreview(false);
          setShowLoading(false);
        }
      });
  };

  const triggerPreview = () => {
    if (!showPreview) {
      loadData();
    } else {
      setShowPreview(!showPreview);
    }
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-1 text-center">
          {!radioDisabled && (
            <input
              id="radioDatasetItens"
              name="radioDatasetItens"
              className={`${style.radioButtonDatasetRadio} form-check-input`}
              onChange={clickEvent}
              type="radio"
              value="1"
              checked={globalStore.getDatasets().includes(label)}
            ></input>
          )}
        </div>

        <div className="col-8">
          <p className="fw-bold mb-0">{label}</p>
        </div>
        <div className="col-1 text-end">
          {showLoading && (
            <div
              className="spinner-border spinner-border-sm text-secondary"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
        <div className="col-2 text-end" onClick={triggerPreview}>
          {!showData &&
            (showPreview ? (
              <img src={PreviewEyeOpen} alt="Preview Eye Closed"></img>
            ) : (
              <img src={PreviewEyeClosed} alt="Preview Eye Open"></img>
            ))}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {showPreview && datasetMetadata && datasetPreview && !showData && (
            <>
              <div className="row mt-3">
                <div className="col">
                  <p>
                    <span className="fw-bold">Rows: {datasetMetadata.row}</span>
                    <span className="ms-5 fw-bold">
                      Columns: {datasetMetadata.column}
                    </span>
                  </p>
                </div>
              </div>
              <Table columns={datasetColumns} rows={datasetPreview} />
            </>
          )}

          {showData && showPreview && (
            <>
              <div className="row mt-3">
                <div className="col">
                  <p>
                    <span className="fw-bold">Rows: {datasetMetadata.row}</span>
                    <span className="ms-5 fw-bold">
                      Columns: {datasetMetadata.column}
                    </span>
                  </p>
                </div>
              </div>
              <Table columns={datasetColumns} rows={datasetPreview} />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default observer(DatasetItem);
