import React, { useContext, useEffect, useState } from 'react';
import DatasetItem from './DatasetItem/DatasetItem';
import style from './DatasetGrid.module.scss';
import globalStore from '../../../Stores/globalStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from 'react-hot-toast';
import openaiService from '../../../Services/API/openaiService';
import ConfirmationModal from '../../../Modals/ConfirmationModal';
import chatStore from '../../../Stores/chatStore';
import ghostsvg from '../../../Assets/Ghost.svg';
import { useNavigate } from 'react-router-dom';
import Input from '../../Common/Input/input';
import lupa from '../../../Assets/lupa.svg';

export default function DatasetGrid() {
  const [listDatasets, setListDatasets] = useState<any[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const navigate = useNavigate();

  function addDataset(item: any) {
    globalStore.cleanDatasets();
    globalStore.addDataset(item);
  }

  const callApiListTables = () => {
    setShowLoading(true);
    openaiService.callApiConnectorListTables('service').then((res) => {
      if (res) {
        setListDatasets(res);
      }
      setShowLoading(false);
    });
  };

  useEffect(() => {
    callApiListTables();
  }, []);

  return (
    <>
      {/* <hr className="m-2 mx-4" /> */}
      <div className="row mt-3 align-items-center">
        <div className="col-1 text-end">
          <h6 className={`px-2 m-0`}>
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              icon={faRefresh}
              onClick={() => callApiListTables()}
            />
          </h6>
        </div>
        <div className="col-9">
          {!showLoading && (
            <div className={`${style.inputWidth} ps-2 pe-2`}>
              <div
                className={`${style.inputWidth} input-group border border-dark rounded`}
              >
                <span className="input-group-append">
                  <div
                    className={`input-group-text bg-transparent border-0 ${style.appendInput}`}
                  >
                    <img src={lupa}></img>
                  </div>
                </span>
                <input
                  className={`form-control border-0`}
                  type="search"
                  placeholder="Procurar..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="col-1 px-4">
          <img
            src={ghostsvg}
            style={{
              // paddingRight: '20px',
              opacity: globalStore.getDatasets().length >= 1 ? 1 : 0.3
            }}
            onClick={(_) => {
              if (globalStore.getDatasets().length >= 1) {
                navigate('/gerargraficos');
              }
            }}
          ></img>
        </div>
      </div>

      <div className="flex-grow-1 w-100 ">
        <div className="h-100 p-3 px-4 pt-0">
          {showLoading ? (
            <div className="row align-items-center text-center">
              <div className="col">
                <div
                  className="spinner-border spinner-border-lg text-secondary"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            listDatasets
              .filter((dataset) => dataset.includes(searchQuery))
              .map((dataset, index) => (
                <div
                  key={index}
                  className="pb-3 pt-3 border-bottom border-dark-subtle"
                >
                  <DatasetItem
                    key={index}
                    label={dataset}
                    clickEvent={(_: any) => {
                      if (chatStore.chatMessages.length > 1) {
                        setConfirmModalOpen(true);
                      } else {
                        addDataset(dataset.toString());
                      }
                    }}
                    radioDisabled={false}
                  />
                </div>
              ))
          )}
        </div>
      </div>
      {confirmModalOpen && (
        <ConfirmationModal
          title={`Deseja remover o ${globalStore.getDatasets().join(',')}`}
          text={
            'Ao remover o dataset, você será direcionado para a página de seleção de dataset e reiniciaremos o chat, tudo bem?'
          }
          open={confirmModalOpen}
          confirmBtnText="Remover dataset"
          onClose={(confirmValue: boolean) => {
            setConfirmModalOpen(false);
            if (confirmValue) {
              globalStore.cleanDatasets();
              chatStore.cleanChat();
            }
          }}
        />
      )}
    </>
  );
}
