import React from 'react';
import Navbar from '../Components/Layout/Navbar/Navbar';
import Sidebar from '../Components/Layout/Sidebar/Sidebar';
import { PrivateRoutes } from '../Routes/PrivateRoutes';
import { PublicRoutes } from '../Routes/PublicRoutes';
import { Toaster } from 'react-hot-toast';

export function AuthorizedContainer() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-3 col-xl-2 bg-light p-0"
          style={{
            zIndex: '10000',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2))'
          }}
        >
          <Sidebar></Sidebar>
        </div>
        <div
          className="col-9 col-xl-10 d-flex flex-column p-0"
          style={{ height: '100vh', overflow: 'hidden' }}
        >
          <Navbar />
          <PrivateRoutes />
        </div>
      </div>
      <Toaster position="bottom-right" reverseOrder={true} />
    </div>
  );
}

export function UnAuthorizedContainer() {
  return <PublicRoutes />;
}
