import React from 'react';

interface TableProps {
  columns: string[];
  rows: { [key: string]: any }[];
}

const Table: React.FC<TableProps> = ({ columns, rows }) => {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="table-secondary text-center">
            {columns.map((column) => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index} className="text-center">
              {columns.map((column) => (
                <td key={column}>{row[column]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
