import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export const apiAuthInstance: AxiosInstance = axios.create({
  baseURL:
    'https://auth.v2.opengalaxy.io/auth/realms/OpenGalaxy/protocol/openid-connect/token',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

export const apiOpenaiInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_OPENAI,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});
