import React, { useEffect } from 'react';
import ButtonC, { IButtonType } from '../../../Components/Common/Button/button';
import { ReactComponent as PastaIcon } from '../../../Assets/pasta-btn.svg';
import { ReactComponent as CarIcon } from '../../../Assets/carrinho.svg';
import { ReactComponent as ConnectorIcon } from '../../../Assets/connector.svg';
import HomePage from '../../../Components/Layout/HomePage/HomePage';
import DatasetGrid from '../../../Components/Specific/DatasetGrid/DatasetGrid';
import { Tooltip } from '@mui/material';
import keycloak from '../../../Services/Keycloak/KCInstance';

export default function PrepararDados() {
  return (
    <div
      className="d-flex flex-column flex-grow-1"
      style={{ overflow: 'hidden scroll' }}
    >
      <HomePage text="Para começar, por favor, selecione no campo abaixo o dataset para gerar visualizações e insights." />
      <div className="d-flex justify-content-start mt-4 mb-3 ms-4">
        <ButtonC
          buttonType={IButtonType.upload_file_btn}
          buttonText="Camada service"
          style={{ width: 'unset', margin: '0px 5px' }}
        />
        <ButtonC
          buttonType={IButtonType.upload_file_btn}
          buttonText="Arquivo Local"
          style={{ width: 'unset', margin: '0px 5px' }}
          title="Em breve disponível"
          disabled
        />
        <ButtonC
          buttonType={IButtonType.upload_file_btn}
          buttonText="Marketplace de dados"
          style={{ width: 'unset', margin: '0px 5px' }}
          title="Em breve disponível"
          disabled
        />
        <ButtonC
          buttonType={IButtonType.upload_file_btn}
          buttonText="Conector"
          style={{ width: 'unset', margin: '0px 5px' }}
          title="Em breve disponível"
          disabled
        />
      </div>
      <div className="flex-grow-1 d-flex flex-column">
        <DatasetGrid />
      </div>
    </div>
  );
}
