import globalStore from '../../../Stores/globalStore';
import ButtonC, { IButtonType } from '../../Common/Button/button';
import './Sidebar.scss';
import React, { useState } from 'react';
import { Observer } from 'mobx-react-lite';
import svgxMark from '../../../Assets/x-mark.svg';
import { useNavigate } from 'react-router-dom';
import imgChatLogo from '../../../Assets/logo_chat2dash.svg';
import ConfirmationModal from '../../../Modals/ConfirmationModal';
import chatStore from '../../../Stores/chatStore';

export default function Sidebar() {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="main_sidebar d-flex flex-column">
      <div className="mt-2">
        <h2 className="sidebar_title mt-2" style={{ color: '#8A38FE' }}>
          <img src={imgChatLogo} style={{ height: '40px', width: 'auto' }} />{' '}
          Chat2Dash
        </h2>
        <hr className="sidebar_hr" />
        <div className="container_files mt-4">
          <p>Arquivos selecionados</p>
          <Observer>
            {() => (
              <>
                {globalStore.getDatasets().map((x: any, index: any) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center mb-2 ml-5"
                  >
                    <p className="text-light-p ">{x}</p>
                    <img
                      src={svgxMark}
                      style={{ marginRight: '10%', opacity: '0.3' }}
                      onClick={() => setConfirmModalOpen(true)}
                    />
                  </div>
                ))}
              </>
            )}
          </Observer>
        </div>
      </div>
      <div className="d-flex">
        <ButtonC
          buttonType={IButtonType.upload_file_btn}
          buttonText="Selecionar dataset"
          onClick={() => navigate('/preparardados')}
        ></ButtonC>
        <div className="container_historic"></div>
      </div>
      {confirmModalOpen && (
        <ConfirmationModal
          title={`Deseja remover o ${globalStore.getDatasets().join(',')}`}
          text={
            'Ao remover o dataset, você será direcionado para a página de seleção de dataset e reiniciaremos o chat, tudo bem?'
          }
          open={confirmModalOpen}
          confirmBtnText="Remover dataset"
          onClose={(confirmValue: boolean) => {
            setConfirmModalOpen(false);

            if (confirmValue) {
              globalStore.cleanDatasets();
              navigate('/preparardados');
              chatStore.cleanChat();
            }
          }}
        />
      )}
    </div>
  );
}
