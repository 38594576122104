import React, { forwardRef } from 'react';
import copySvg from '../../../Assets/copy.svg';
import './button.scss';
import { copyTextToClipboard } from '../../../Utils/globalFunctions';

export enum IButtonType {
  stacked_button,
  copy_button,
  upload_file_btn,
  purple_button,
  simple_button_with_class,
  cancel_button
}

interface IButtonProps {
  buttonType: IButtonType;
  buttonText: string;
  buttonIcon?: any;
  textToCopy?: string;
  classes?: string;
  [key: string]: any;
}

function Button(
  {
    buttonType,
    buttonText,
    buttonIcon = null,
    textToCopy = '',
    classes = '',
    ...rest
  }: IButtonProps,
  ref: any
) {
  switch (buttonType) {
    case IButtonType.stacked_button:
      return (
        <button className="stackedbutton" {...rest} ref={ref}>
          {buttonIcon}
          {buttonText}
        </button>
      );
    case IButtonType.copy_button:
      // @ Obs: se você usar esse tipo de botão você precisa passar a propriedade textToCopy
      return (
        <button
          ref={ref}
          className="copyButton d-flex gap-1 align-items-center"
          {...rest}
          onClick={(_) => copyTextToClipboard(textToCopy)}
        >
          <img src={copySvg} />
          {buttonText}
        </button>
      );
    case IButtonType.upload_file_btn:
      return (
        <button ref={ref} className="uploadFileBtn" {...rest}>
          {buttonText}
        </button>
      );
    case IButtonType.purple_button:
      return (
        <button ref={ref} className="purple-button" {...rest}>
          {buttonText}
        </button>
      );
    case IButtonType.cancel_button:
      return (
        <button ref={ref} className="cancel-button" {...rest}>
          {buttonText}
        </button>
      );
    case IButtonType.simple_button_with_class:
    default:
      return (
        <button className={classes} ref={ref} {...rest}>
          {buttonText}
        </button>
      );
  }
}

export default forwardRef(Button);
