import JSZip from 'jszip';
import { toast } from 'react-hot-toast';

// Pega o nome dos arquivos dentro do ZIP
export async function getZipFileNames(
  zipContent: ArrayBuffer
): Promise<string[]> {
  const zip = new JSZip();

  const loadedZip = await zip.loadAsync(zipContent);
  const fileNames: string[] = [];
  loadedZip.forEach((relativePath, _) => {
    fileNames.push(relativePath);
  });

  return fileNames;
}

// Extrai todos os arquivos de um ZIP.
export async function extractFileFromZip(zipContent: ArrayBuffer) {
  const zip = new JSZip();

  try {
    const loadedZip = await zip.loadAsync(zipContent);
    const zipFiles: { fileName: string; content: any }[] = [];

    const promises = Object.keys(loadedZip.files).map(async (zipName) => {
      const file = loadedZip.files[zipName];
      const content = await file.async('text');
      zipFiles.push({ fileName: zipName, content });
    });

    await Promise.all(promises);
    return zipFiles;
  } catch (error) {
    toast.error('Não foi possível extrair dados do arquivo zip.', {
      duration: 2500
    });
    throw error;
  }
}
