import React, { useState } from 'react';
import style from '../DatasetGrid/DatasetGrid.module.scss';
import Open from '../../../Assets/seta.svg';
import DatasetItem from '../DatasetGrid/DatasetItem/DatasetItem';
import globalStore from '../../../Stores/globalStore';
import './ViewFiles.scss';

export default function ViewFiles() {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const triggerPreview = () => {
    setShowPreview(!showPreview);
  };
  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="w-75">
        <hr className="hrStyle" />
        <div
          className="text-end d-flex align-items-center justify-content-center gap-2 p-2"
          onClick={triggerPreview}
        >
          <div className="viewMessage">
            Visualize os arquivos que você está utilizando
          </div>
          <img
            className={`view ${!showPreview ? '' : 'transform-preview'}`}
            src={Open}
            alt="Open"
          ></img>
        </div>
        <div className="row">
          <div className="col">
            {showPreview && (
              <DatasetItem
                showData={true}
                label={globalStore.getDatasets()?.at(0)}
                radioDisabled={true}
              />
            )}
          </div>
        </div>
        <hr className="hrStyle" />
      </div>
    </div>
  );
}
