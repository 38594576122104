import React, { useEffect, useMemo, useRef, useState } from 'react';
import './index.scss';
import Button, { IButtonType } from '../Button/button';
import userPolygon from '../../../Assets/user_polygon.svg';
import semantixPolygon from '../../../Assets/semantix_polygon.svg';
import userAvatar from '../../../Assets/user_avatar.svg';
import stixAvatar from '../../../Assets/stix_avatar.svg';
import Loader from '../Loader/Loader';
import globalStore from '../../../Stores/globalStore';
import chatStore from '../../../Stores/chatStore';
export interface IChatMessageProps {
  chatMessage: string;
  promptFlagSide: boolean;
  loading?: boolean;
  typing?: boolean;
}

const ChatMessage: React.FC<IChatMessageProps> = ({
  chatMessage,
  promptFlagSide,
  loading = false,
  typing = true
}) => {
  const typingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typingRef?.current && typing) {
      typingRef.current.classList.remove('typing-hide');
      typingRef.current.classList.remove('typing');
      if (promptFlagSide) {
        const numSteps = chatMessage.length * 0.02;
        typingRef.current.classList.add('typing');
        typingRef.current.style.animation = `typing ${numSteps}s steps(${chatMessage.length}), blink 0.5s step-end infinite alternate`;
        chatStore.updateChatMessageTyping();
        setTimeout(() => {
          if (typingRef?.current) typingRef.current.style.whiteSpace = 'normal';
        }, numSteps * 1000);
      }
    } else {
      if (!typing && typingRef?.current) {
        typingRef.current.classList.remove('typing-hide');
        typingRef.current.classList.remove('typing');
      }
    }
    document.getElementById('chat-01')?.scrollTo({
      top: document.getElementById('chat-01')?.scrollHeight,
      behavior: 'smooth'
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div className="d-flex mt-4 mb-5 show-effect">
      <div
        className={`d-flex w-100 ${
          !promptFlagSide ? 'justify-content-end' : ''
        }`}
      >
        {promptFlagSide && (
          <>
            <img src={stixAvatar} style={{ marginRight: '10px' }} />
            <img src={semantixPolygon} style={{ marginRight: '-2px' }} />
          </>
        )}
        <div className={promptFlagSide ? 'semantixMessage' : 'userMessage'}>
          {loading ? (
            <div
              className="d-flex w-100 justify-content-start"
              style={{ marginLeft: '25px' }}
            >
              <Loader />
            </div>
          ) : (
            <div>
              <div
                className={`${
                  promptFlagSide ? 'typing-hide prompt-align' : 'user-align'
                }`}
                ref={typingRef}
              >
                {chatMessage}
              </div>
              <Button
                style={{
                  position: 'absolute',
                  right: '0',
                  marginRight: '17px',
                  marginTop: '37px'
                }}
                buttonType={IButtonType.copy_button}
                buttonText="Copiar"
                textToCopy={chatMessage}
              />
            </div>
          )}
        </div>
        {!promptFlagSide && (
          <>
            <img src={userPolygon} style={{ marginLeft: '-2px' }} />
            <img src={userAvatar} style={{ marginLeft: '10px' }} />
          </>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
