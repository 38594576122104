import { AxiosRequestConfig } from 'axios';
import { apiOpenaiInstance } from '../axiosInstances';
import { toast } from 'react-hot-toast';
import globalStore from '../../Stores/globalStore';

class OpenAiService {
  async generateGraphs(objetivo: string) {
    try {
      const { data } = await apiOpenaiInstance.post<ArrayBuffer>(
        `/sdp_generate_graphs?objective=${objetivo}`,
        {
          schema_str: 'service',
          table_str: globalStore.datasets.join(','),
          description_str: JSON.stringify({})
        },
        { responseType: 'arraybuffer' }
      );
      return data;
    } catch (error: any) {
      if (error.response.status === 422)
        toast.error('Objetivo não é valido rota: /sdp_generate_graphs');

      if (error.response.status === 412)
        toast.error(
          'Conteúdo ofensivo política de uso openai rota: /sdp_generate_graphs'
        );
    }
  }

  async sdp_reprompt(objective: string, code: string) {
    try {
      const { data } = await apiOpenaiInstance.post<ArrayBuffer>(
        `/sdp_reprompt?objective=${encodeURIComponent(
          objective
        )}&code=${encodeURIComponent(code)}`,
        {
          schema_str: 'service',
          table_str: globalStore.datasets.join(',')
        },
        { responseType: 'arraybuffer' }
      );
      return data;
    } catch (error) {
      toast.error(
        'Erro ao chamar a rota /sdp_reprompt' + JSON.stringify(error)
      );
    }
  }

  async callApiConnectorListTables(schemaStr: string) {
    try {
      const body = {
        schema_str: schemaStr
      };

      const { data } = await apiOpenaiInstance.post('/sdp_list_tables', body);
      return data;
    } catch (error) {
      toast.error('Erro ao carregar lista. Favor tentar novamente');
    }
  }

  async callApiConnectorMetadataPreview(schemaStr: string, tableStr: string) {
    try {
      const body = {
        schema_str: schemaStr,
        table_str: tableStr
      };

      const { data } = await apiOpenaiInstance.post(
        '/sdp_table_metadata_preview',
        body
      );
      return data;
    } catch (error) {
      toast.error('Erro ao carregar preview dos dados. Favor tentar novamente');
    }
  }
}

const openaiService = new OpenAiService();
export default openaiService;
