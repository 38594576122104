import React, { useEffect, useState } from 'react';
import './App.scss';
import * as Containers from '../Containers';
import { BrowserRouter } from 'react-router-dom';
import userStore from '../Stores/userStore';
import { observer } from 'mobx-react-lite';
import keycloak from '../Services/Keycloak/KCInstance';

function App({ auth }: { auth: any }) {
  return (
    <div style={{ fontSize: '12px' }}>
      <BrowserRouter>
        {auth ? (
          <Containers.AuthorizedContainer />
        ) : (
          <Containers.UnAuthorizedContainer />
        )}
      </BrowserRouter>
    </div>
  );
}

export default observer(App);
