import { makeAutoObservable } from 'mobx';
import React from 'react';

class GlobalStore {
  datasets: Array<any> = [];

  constructor() {
    makeAutoObservable(this);
  }

  addDataset(item: any) {
    this.datasets.push(item);
  }

  getDatasets() {
    return [...this.datasets];
  }

  removeDataset(item: any) {
    const index = this.datasets.findIndex((i) => i === item);
    if (index !== -1) {
      this.datasets.splice(index, 1);
    }
  }

  cleanDatasets() {
    this.datasets = [];
  }
}

const globalStore = new GlobalStore();
export default globalStore;
