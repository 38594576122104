import logo from '../../../Assets/logo.svg';
import React, { useEffect } from 'react';
import TextBox from '../../../Components/UI/Input';
import TextBoxWithButton from '../../../Components/UI/TextBoxWithButton';
import CopyButton from '../../../Components/UI/CopyButton';
import keycloak from '../../../Services/Keycloak/KCInstance';
import HomePage from '../../../Components/Layout/HomePage/HomePage';
import Loader from '../../../Components/Common/Loader/Loader';

export default function Login() {
  useEffect(() => {
    keycloak.login({
      redirectUri: window.location.origin
    });
  }, []);

  return (
    <div className="container d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <HomePage text="Carregando aplicação" />
        <Loader></Loader>
      </div>
    </div>
  );
}
