import logoHomePage from '../../../Assets/logo_chat2dash.svg';
import './HomePage.scss';
import React from 'react';
interface Props {
  text: string;
}

const HomePage: React.FC<Props> = ({ text }) => {
  return (
    <div className="home-page">
      <img className="img-home-page" src={logoHomePage} />
      <div className="title-home-page">Olá! Você está no Chat2Dash.</div>
      <div className="text-home-page">{text}</div>
    </div>
  );
};

export default HomePage;
