import React, { useEffect, useState } from 'react';

const styleScrollbar = `
    /* width */
    ::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #CED1D2;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 20px;
      transition: 0.1s;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #bbb;
      transition: 0.1s;
    }
    `;

interface IEmbedGraphicsProps {
  content: any;
}

export default function EmbedGraphic({ content }: IEmbedGraphicsProps) {
  const [loading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  }, []);

  function onLoad(e: React.SyntheticEvent<HTMLIFrameElement, Event>) {
    const iframeDoc =
      e.currentTarget.contentDocument ||
      e.currentTarget.contentWindow?.document;
    const style = document.createElement('style');
    style.innerHTML = styleScrollbar;
    iframeDoc?.head.appendChild(style);
  }

  return (
    <div>
      {loading ? (
        <div style={{ height: '400px' }}></div>
      ) : (
        <iframe
          style={{
            width: '100%',
            height: '400px',
            overflow: 'hidden',
            border: 'none'
          }}
          srcDoc={content}
          onLoad={(e) => onLoad(e)}
        ></iframe>
      )}
    </div>
  );
}
